<template>
    <page-header-wrapper>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper" v-action:query>
                <a-form layout="inline">
                    <a-row :gutter="24">
                        <a-col :md="8" :sm="24">
                            <a-form-item label="时间筛选：">
                                <a-range-picker @change="getDate" v-model="queryParam.time" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="关键词">
                                <a-input style="width: 80%" placeholder="请输入" v-model="queryParam.keyword" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="4 || 24" :sm="24">
                            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="loginTime" slot-scope="loginTime">
                    {{loginTime|time}}
                </span>
                <span slot="username" slot-scope="username">
					{{username}}
                </span>
				<span slot="ip" slot-scope="ip">
					{{ip}}
				</span>
				<span slot="os" slot-scope="os">
					{{os ||'-'}}
				</span>
            </s-table>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
		getLogList
    } from '@/api/log'

    const columns = [
        // {
        //     title: '序号',
        //     scopedSlots: {
        //         customRender: 'serial'
        //     },
        //     align:'center',
        //     width:'90px'
        // },
        {
            title: '登陆时间',
            dataIndex: 'create_time',
            align:'center',
			scopedSlots: {
			    customRender: 'loginTime'
			},
			width:'200px'
        },
		{
		    title: '用户名',
		    dataIndex: 'login_name',
			scopedSlots: {
			    customRender: 'username'
			},
		    align:'center'
		},
        {
            title: '登陆IP',
            dataIndex: 'create_ip',
            align:'center',
            scopedSlots: {
                customRender: 'ip'
            }
        },
        {
            title: '操作系统',
            dataIndex: 'os',
            scopedSlots: {
                customRender: 'os'
            },
            align:'center'
        }
    ]

    const statusMap = {
        0: {
            status: 'default',
            text: '全部'
        },
        1: {
            status: 'default',
            text: '待审核'
        },
        2: {
            status: 'success',
            text: '审核通过'
        },
        3: {
            status: 'error',
            text: '审核拒绝'
        }
    }

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                visible: false,
                advanced: false,
                // 查询参数
                queryParam: {
                    time: [],
                    keyword: '',
                    startTime: '',
                    endTime: '',
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
                    //console.log('requestParameters',requestParameters)
                    return getLogList(requestParameters)
                        .then(res => {
                            return res.data
                        })
                },
                
                modalVisible: false, //发布弹窗
                url:'',   //微信文章url

                title:'',
                img_url:'',
                link_url:'',
				
				form:{
					id:'',
					name:'',
					address:'',
					lat:'',
					lng:''
					
				},
				selectedAdd: undefined,
				addressData:[]
            }
        },
		mounted(){
			
		},
        methods: {
            getDate(date, dateString) { //时间筛选
                this.queryParam.time = dateString
                this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0]: ''
                this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1] : ''
            },
            reset() { //重置
                this.queryParam.time = null
				this.queryParam.startTime = ''
				this.queryParam.endTime = ''
                this.queryParam.keyword = ''
                this.$refs.table.refresh(true)
            }
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            },
            statusFilter(type) {
                return statusMap[type].text
            },
            statusTypeFilter(type) {
                return statusMap[type].status
            }
        }
    }
</script>


<style scoped>
.deliv_item{display:flex;align-items: center;margin-top: 36px;}
.deliv_item_label{width: 120px;}
.deliv_item_label i{font-style: normal;color: #FF3B30;}
.modal_item{display: flex;margin-top: 10px;}
.modal_item div:nth-child(1){min-width: 112px;}
.modal_item div:nth-child(2) img{margin-top: 5px;}

.item{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}
.item_label{
	min-width:100px;
}
.item_label i{
	font-style: normal;
	color: #FF3B30;
	margin-right: 3px;
}
.item_main{
	min-width: 1px;
	width: 100%;
}
</style>